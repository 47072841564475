body,
html {
  font-family: Lato;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#root {
  //   height: 100vh;
  //   overflow-y: hidden;
  font-family: Lato;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}

a {
  text-decoration: none;
  color: #040403;
}

.email-link a:hover {
  text-decoration: underline;
  color: #040403;
}
.spinner-div {
  height: 500px;
}

.navbar {
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 10px;
  opacity: 95%;
  padding-right: 0 !important;
}

.modal-open {
  padding-right: 0 !important;
}

.nav-bottom {
  display: flex;
  gap: 100px;
  margin-bottom: 40px;
  max-width: 848px;
}

.btn-close {
  opacity: 1;

  &:hover {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

.work-carousel {
  .btn-close {
    &:hover {
      filter: invert(61%) sepia(58%) saturate(2426%) hue-rotate(324deg)
        brightness(97%) contrast(106%) !important;
    }
  }
  .modal-fullscreen .modal-content {
    height: 95%;
    width: 98%;
    border-radius: 4px;
  }
  .modal-header {
    padding: 14px 40px 0px;
  }
  .modal-body {
    .d-flex.img-sm.mt-2{
      display: flex;
      justify-content: center;
    }
    padding: 0 40px 15px 40px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    .display-chips {
      gap: 20px;
    }
  }
  .modal-dialog-centered {
    justify-content: center;
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
    height: 1.5rem;
    width: 1.5rem;
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    height: 1.5rem;
    width: 1.5rem;
  }
  .carousel-control-prev {
    width: 3%;
    margin-left: -40px;
  }
  .carousel-control-next {
    width: 3%;
    margin-right: -40px;
  }
}

.color-orange {
  color: $color_orange;
}

.color-gray {
  color: $color-gray-two;
}

.color-white {
  color: #ffffff;
}

.mb-10px {
  margin-bottom: 10px;
}

.mb-40px {
  margin-bottom: 40px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-minus60 {
  margin-top: -60px;
}

.mt-120px {
  margin-top: 120px;
}

.pt-80 {
  padding-top: 80px;
}

.fw-300 {
  font-weight: 300;
}

.fw-600 {
  font-weight: 600;
}

.fw-light {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.fs-small {
  font-size: 14px;
}

.fs-24px {
  font-size: 24px;
}

.opacity-60 {
  opacity: 60%;
}

.chat-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  .chat-icon {
    padding: 17px 15px;
    border-radius: 29px;
    background-color: rgba(4, 4, 3, 0.7);
    cursor: pointer;
    float: right;
    img {
      height: 24px;
    }
    .drop-line {
      color: #ffffff;
      padding-right: 10px;
      display: none;
    }
    &:hover {
      background-color: $color_orange;
      .drop-line {
        display: inline-block;
      }
    }
  }
  .chat-box {
    height: 300px;
    padding: 10px;
    width: 250px;
    background-color: #ffffff;
    margin-bottom: 10px;
    border: 1px solid $color_orange;
    border-radius: 3px;
    position: relative;
    .msg-input {
      position: absolute;
      bottom: 5px;
    }
  }
}

.menu-icon {
  cursor: pointer;

  &:hover {
    color: $color_orange;
  }
}

.offcanvas.offcanvas-bottom {
  height: 100%;
  // .offcanvas-body {
  //     padding: px 290px;
  // }
}

.navbar {
  padding-top: 10px;
  padding-bottom: 10px;
}

.main-nav {
  max-width: 848px;
  margin: auto;

  .nav-link {
    border-bottom: 0.5px solid #040403;
    font-size: 4rem;

    .arrow-icon {
      transition: 0.1s ease-in;
    }

    &:hover {
      color: #ffffff;

      .arrow-icon {
        transform: translateX(50%);
      }
    }
  }
}

.home-banner {
  margin-top: 100px;
  padding: 0 5rem;
  background-image: url(../assets/images/orange-circle.svg);
  background-repeat: no-repeat;
  background-size: 35rem;
  background-position: 72% 35%, 70% 20%, top right;
  height: 650px;
  position: relative;

  .hi-there {
    letter-spacing: 1.8px;
  }

  p {
    max-width: 524px;
  }

  .banner-img {
    height: 620px;
    position: absolute;
    left: 45%;
    // top: 10%;
  }

  .home-buttons {
    display: flex;
    gap: 24px;
  }

  .innovate {
    font-size: 32px;
    z-index: 1;
    font-weight: 600;
    letter-spacing: 3.84px;
  }
}

.banner-heading {
  letter-spacing: 6.62vw;
  font-weight: 500;
  font-size: 15.2vw;
  line-height: 35cqh;
  margin-left: -19px;
}

.heading-orange {
  color: $color_orange;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  letter-spacing: 1.2px;
}

.about-banner {
  background-image: url(../assets/images/about-background.png);
  background-repeat: no-repeat;
  background-size: 560px;
  background-position: 90% top;
  padding: 0 80px 80px;

  .about-heading {
    font-size: 12.5rem;
    font-weight: 500;
    letter-spacing: 30.6px;
    line-height: 31cqh;
    padding-top: 80px;
  }

  .hr-length {
    width: 100%;
    margin-top: 4px;
    margin-right: 6px;
  }
}

.scroll-down-animation {
  height: 80px;
  width: 140px !important;
  position: absolute;
  bottom: 20px;
  z-index: 1;
}

.values-section {
  background-image: url(../assets/images/values-background.png);
  padding: 80px 0;
  background-size: cover;

  .values {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 872px;
    gap: 24px;
    margin: auto;
  }

  .value {
    width: 200px;
    background-color: $color_orange;
    padding: 12px;
    flex: 0 0 21.333333%;
    border-radius: 3px;
    border: solid 1px rgba(255, 255, 255, 0.2);
  }

  .tools {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 872px;
    gap: 24px;
    margin: auto;
    margin-top: 120px;
    margin-bottom: 25px
  }

  .tool-section {
    background-color: #191919;
    width: 200px;
    padding: 20px;
    text-align: center;
    border-radius: 3px;

    img {
      height: 120px;
    }
    
  }
  .tool-section:nth-child(8) img {
    height: 63px;
    margin-bottom: 17%;
    margin-top: 18%;
  }
}

.passion-section {
  background-image: url(../assets/images/passion-background.png);
  padding: 80px;
  background-size: cover;

  img {
    max-width: 100%;
  }
}

.work-experience-section {
  padding: 80px;
  background-image: url(../assets/images/work-exp-background.png);
  background-size: cover;
  position: relative;
  min-height: 1360px;
  h1 {
    position: absolute;
    z-index: 1;
  }
  .sub-heading {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
}

.vertical-line {
  border-left: 1px solid rgba(253, 112, 80, 0.35);
  position: absolute;
  top: 0;
  margin-left: 26%;
  padding: 25px;
  padding-top: 280px;

  .main-heading {
    letter-spacing: 1.28px;
  }

  .year-section {
    left: -84px;
    position: absolute;
  }

  .year {
    width: 54px;

    p {
      float: right;
      letter-spacing: 2.16px;
    }
  }

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 20px;
    top: 7px;
    background-image: radial-gradient(
        circle at center,
        $color_orange 5px,
        transparent 5px
      ),
      radial-gradient(circle at center, red 5px, transparent 5px);
    background-size: 20px 20px;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  &:before {
    left: -11px;
  }
}

.services {
  padding: 60px 50px;
  background-color: $color-grey;

  .col {
    border-right: 1px solid $color-gray-three;
    padding: 0 30px;

    &:last-child {
      border-right: none;
    }

    margin-bottom: 60px;
  }
}

.service {
  min-height: 530px;

  img {
    height: 40px;
  }

  .title {
    white-space: pre-line;
    word-spacing: 100px;
  }

  .service-count {
    color: $color_orange;
    font-size: 80px;
    font-weight: 300;
  }
  .bullet-list {
    color: #5e6472;
    font-size: 15px;
    padding-bottom: 7px;
    .list-item {
      padding-bottom: 3px;
    }
  }
}

.text-gray {
  color: $color-gray-two;
  font-size: 18px;
}

@keyframes slidein-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slidein-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-10%);
  }
}

.button-dark {
  border-radius: 0;
  position: relative;
  padding: 16px 30px;
  text-align: center;
  transition: all 0.3s ease;

  span {
    transition: all 0.3s ease;
  }

  &:hover {
    transition: transform 0.5s ease-in;

    .button-content {
      margin-left: -20px !important;
      margin-right: 30px;
    }

    span:last-child {
      opacity: 1;
      margin-right: -20px;
    }

    background-color: $color_orange;
    border-color: $color_orange;
  }
}

.work-section {
  padding: 80px;
  background-image: url(../assets/images/background-one.png);
  background-size: cover;
  .divider {
    padding: 0px 15px;
  }
}

.work {
  border-bottom: 1px solid #3a3e46;
  padding: 0 30px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0 !important;
  }

  .img-container {
    position: relative;
    background-color: $color_orange;
    overflow: hidden;
    border-radius: 4px;

    &:hover .image-large {
      opacity: 0.1;
      transform: scale(1.2);
    }

    &:hover .middle {
      opacity: 1;
    }
  }

  .image-large {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    backface-visibility: hidden;
    transition: 0.5s all ease-in-out;
    max-height: 75vh !important; 
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .view-project {
    cursor: pointer;
    color: white;
    font-size: 20px;
    font-weight: 500;

    .arrow-icon {
      font-size: 28px;
    }

    &:hover .arrow-icon {
      transform: translateX(50%);
    }
  }
}

.work-title {
  
  a {
    color: $color_orange;
    &:hover {
      text-decoration: underline;
    }
  }
}

.carousel {
  img {
    max-height: 69vh;
  }
}

.modal-body {
  padding-top: 0px;
}

.img-sm {
  img {
    max-height: 120px;
    max-width: 180px;
  }

  .img-container {
    background-color: black;
  }

  .img-active {
    border: 2px solid $color_orange;
  }

  .img-not-active {
    opacity: 0.2;
  }

  gap: 16px;
}

.button-round {
  border-radius: 17.5px;
}

.pill-orange {
  padding: 5px 14px 6px;
  border-radius: 14px;
  background-color: rgba(253, 112, 80, 0.15);
  color: $color_orange;
  font-size: 14px;
  align-self: center;
  margin-left: 30px;
}

.button-outline {
  border-radius: 0;
  position: relative;
  padding: 16px 30px;
  text-align: center;
  transition: all 0.3s ease;

  span {
    transition: all 0.3s ease;
  }

  &:hover {
    transition: transform 0.5s ease-in;

    .button-content {
      margin-left: -20px;
      margin-right: 25px;
    }

    span:last-child {
      opacity: 1;
      margin-right: -20px;
    }

    background-color: $color_orange !important;
    border-color: $color_orange !important;
  }
}

.image-large {
  max-width: 100%;
}

.design-preocess {
  background-image: url(../assets/images/design-process-background.png),
    url(../assets/images/path.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: 100% center, 121% 47%, top right;
  background-size: cover, 80%;
  padding: 80px;

  .text-sm-oragne {
    letter-spacing: 1px;
  }

  .process-section {
    .process:nth-child(2) {
      margin-left: 30%;
    }

    .process:nth-child(3) {
      margin-left: 60%;
    }
  }

  .process {
    max-width: 518px;
  }

  .process-count {
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 85%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 85%);
    font-size: 157px;
    color: #e1e3e4;
    font-weight: bold;
    line-height: 22cqh;
  }

  .process-detail {
    margin-top: -60px;
    margin-left: 100px;
  }
}

.text-sm-oragne {
  font-size: 20px;
  color: $color_orange;
  font-weight: bold;
}

.text-small {
  font-size: 20px;
}

.faq-section {
  background-image: url(../assets/images/background-one.png);
  background-size: cover;
  padding: 80px 20px;

  .faq {
    max-width: 1000px;
  }

  .color-orange:hover {
    text-decoration: underline;
  }

  // padding: 80px 188px;
  .accordion-item {
    margin: auto;

    .accordion-button {
      padding-left: 0;
      padding-right: 0;
      font-size: 24px;
      font-weight: 500;
      padding: 29.5px 0;

      &:focus {
        box-shadow: none;
      }
      &:not(.collapsed) {
        box-shadow: none;
      }
    }

    .accordion-body {
      padding-left: 0;
      padding-right: 0;
      white-space: pre-line;
    }

    .accordion-button.collapsed::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fd7050'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    .accordion-button {
      color: $accordion-active-color;
      background-color: $accordion-active-bg;

      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fd7050'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
  }
}

.faq-heading {
  margin-bottom: 70px;
}

.contact {
  margin-top: 90px;
  .contact-form {
    background-image: url(../assets/images/contact-background.png);
    background-size: cover;
    padding: 49px 60px 70px 80px;

   
   
    .form-control:focus {
      box-shadow: none;
    }

    .form-check-input {
      box-shadow: none;

      &:checked {
        background-color: $color_orange;
        border-color: $color_orange;
      }

      &:hover {
        border-color: $color_orange;
      }
    }
    a {
      color: $color_orange;
      &:hover {
        text-decoration: underline;
      }
    }
  } 
  form {
    button {
      border:none;
      
    }
    .label {
      font-weight: bold;
    }
    #firstRow {
      display:flex
    }
    .input_cont{
      display: flex;
      flex-direction: column;
      width: 47%;
      margin-right: 3%;
      margin-bottom: 7%;
      label{
        margin-bottom: 4%;
      }
      input{
        border-top:transparent ;
        border-right:transparent ;
        border-left:transparent ;
        border-bottom: 0.5px solid #00000094;
        padding-bottom: 2%;
      }
    }
    .cb_cont{
      display: flex;
      margin-bottom: 4%;
      .c1 {width: 37%;
          div{
          display: flex;
          align-items: center !important;
          justify-content: flex-start !important;
          }}
      .c2 {width: 63%;
          div{
          display: flex;
          align-items: center !important;
          justify-content: flex-start !important;
        }}

      div{
        
        label{
          padding-left: 4%;
          margin-bottom: 0;
          width: 90% !important;
        }
      }
    }
  }

}

.contact-confirm {
  background-image: url(../assets/images/contact-background-two.png);
  background-size: contain;
  background-repeat: no-repeat;
  .col-md-5 {
    align-self: center;
  }
  img {
    height: 440px;
  }
  .contact-submit {
    margin-top: 90px;
    padding: 40px 0px 80px 80px;
    ul {
      padding-left: 15px;
      li {
        padding-top: 10px;
        &:hover {
          text-decoration: underline;
        }
        cursor: pointer;
        letter-spacing: normal;
        font-weight: 600;
        font-size: 18px;
        a {
          color: $color_orange;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.footer {
  border-top: 4px solid $color_orange;
  padding: 50px 80px 0 80px;
  background-image: url(../assets/images/footer-background.png);
  background-size: contain;
  background-repeat: no-repeat;

  .footer-nav {
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 90px;
    a {
      font-weight: bold;
      color: #040403;
      &:hover {
        color: $color_orange;
      }
    }
  }

  .navbar {
    background-color: transparent;
  }

  .nav-link {
    font-weight: bold;
    color: #040403;
    &:hover {
      color: $color_orange;
    }
  }

  .nav-icons {
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 24px;
    svg {
      height: 30px;
      width: 30px;
      &:hover path:first-child {
        fill: $color_orange !important;
      }
    }
    .fiverr-icon {
      svg {
        &:hover path {
          fill: $color_orange !important;
        }
      }
    }
  }
}
.modal-open {
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 0 !important;
}



////////////////////////////////////////////////////////////////// 1900
/////////////////////////  HOME

@media screen and (min-width: 1904px) {

  .contact {
    .col-md-5{
      width: 46%;
    }
   .contact-form{
  width: 54%;  
  .color-gray{
    margin-top: 2%;
  }
  form{
      margin-top: 1.50rem !important;
  }
  }
}
.carousel{margin-top: 2%;}
  .carousel img {
    max-height: 73vh;
}
  .navbar {
    .container {
      min-width: 99%;
    }
  }
  .home-banner {
    background-size: 37rem;
    height: 720px;

    .d-flex .justify-content-between {
      width: 99%;
    }
    .intro-line {
      font-size: 30px;
      letter-spacing: 2px;
      display: flex;
      align-items: center;
      img{
        width: 60px;
        height: 60px;
      }
    }
    .banner-heading {
      font-size: 16.3vw;
      line-height: 30cqh;

      font-size: 18.7vw;
      letter-spacing: 4.7vw;
    }
    .banner-img {
      left: 51%;
      height: 690px;
    }
    p {
      max-width: 650px;
    }
    .text-gray {
      font-size: 22px;
      max-width: 49%;
      margin-top: 1%;
    }
    .innovate {
      font-size: 46px;
    }
  }
  .about-banner .about-heading {
    font-size: 16rem;
    letter-spacing: 50.6px;
    line-height: 27cqh;
  }

.service {
  min-height: 44vh;

  .title{
     white-space: normal;
     word-spacing:0px;
  }

}
.work-title{
  margin-left: 1%;
  width: 98%
}

.about-banner{
  background-size: 26%;
  background-position: 95%;
  padding: 0 80px 59px;
  margin-top: 3%;

  .about-heading{
    letter-spacing: 48.6px;
  }
}
.main-nav{
  margin-top: 9%;
}
.values-section .values {
  max-width: 65%;

  .value{
    .fs-small {
      font-size: 20px;
  }
    .mb-1.mt-1{
      font-size: 25px;
    }
    img{
      width: 30%;
    }
  } 
}

@media screen and (min-height: 1200px){
  .home-banner {
    background-size: 35rem;
    height: 55vh;
    background-position: 67% 25%, 70% 20%, top right;
    .banner-img {
      left: 49%;
      height: 653px;
      top: 6%;
  }
}
.service {
  min-height: 32vh;
}
.work-carousel .modal-fullscreen .modal-content {
  height: 72%;
}
}
}
/////////////////////////////////////////////////////////////////////////////////////////

@media screen and (min-width: 1640px) and (max-width: 1904px){
  
  .navbar .container {
    margin-top: 1%;
    max-width: 97%;
  }
  .home-banner{

    height: 704px;

    .d-flex .justify-content-between {
    width: 97%;
    }
    background-position: 65% 35%, 70% 20%, top right;
    .text-gray{
      max-width: 42%;
    font-size: 17px;
    }
  }
  .service {
    min-height: 58vh;
  
    .title{
       white-space: normal;
       word-spacing:0px;
    }
  
  } 

  .work-title {
    width: 99%;
    margin-left: 1%;
  }
  .about-banner{
    background-size: 30%;
  background-position: 86% 44%;
  }
  
  .about-banner .about-heading {
    font-size: 13.5rem;
}

.values-section .values {
  max-width: 65%;

  .value{
    .fs-small {
      font-size: 17px;
  }
    .mb-1.mt-1{
      font-size: 20px;
    }
    img{
      width: 30%;
    }
  } 
}

.contact-form{
.color-gray{
  margin-top: 2%;
}
form{
    margin-top: 2.25rem !important;
}
}
.main-nav{
  margin-top: 5%;
}
.carousel{margin-top: 2%;}
}

@media screen and (min-width:1488px) and (max-width:1640px) {

  form {
    margin-top: 4%;
    .contact form .input_cont {
      margin-bottom: 5%;}
  }
  .home-banner {background-position: 69% 35%, 70% 20%, top right;
    
    .d-flex .justify-content-between {
      width: 98%;
    }

    .text-gray{
      max-width: 43%;
      font-size: 16px;
    }
  }

  .navbar.container {max-width: 99%;}

  .work-title{
    margin-left: 1%;
  }
  .about-banner{
    margin-top: 2%;
    background-size: 29%;
    background-position: 91% 39%;
  }

  .values-section .values {
    max-width: 69%;
  
    .value{
      height: 154px;

      .fs-small {
        font-size: 16px;
    }
      .mb-1.mt-1{
        font-size: 20px;
      }
      img{
        width: 30%;
      }
    } 
  }
}


///////////////////////////////////////////////////////////////////////////////////////////

@media screen and (min-width:1300px) and (max-width:1488px) {

  .navbar .container {
    max-width: 99%;
  }

  .home-banner .banner-img {
    height: 515px;
}

.home-banner{
  background-size: 29rem;
  background-position: 66% 5%, 70% 20%, top right;
  height: 650px;
}
p.fw-600.mb-0.pt-80.intro-line {
  padding-top: 4%;
}
.home-banner .text-gray {
  max-width: 47%;
  font-size: 16px;
}

.contact {

  .p-0.col-md-5 {
    width: 48%;
  }
  .contact-form{
    padding: 3% 3% 5% 3%;
    width: 52%;
    .color-gray{
      margin-top: 2%;
    }
    form{
        margin-top: 2.25rem !important;
    }
    }
}
  
  .design-preocess {
    background-position: 100% center, 165% 55%, top right;
}

.work-title{
  margin-left: 1%;
  width: 98%;
}

.work-section {
  padding: 49px;}

.about-banner .about-heading {
  font-size: 9.7rem;
}
.about-banner{

  background-size: 35%;
  background-position: 100% 6%;
  padding: 0 80px 80px;
}

.service {
  min-height: 79vh;
}

.values-section .tools {
  margin-bottom: 4%;
}

.values-section .values {
  max-width: 69%;

  .value{
    height: 144px;

    .fs-small {
      font-size: 0.9rem;
  }
    .mb-1.mt-1{
      font-size: 20px;
    }
    img{
      width: 30%;
    }
  } 
}

}

////////////////////////////////////////////////////////////////////////////////////////

@media screen and (min-width:1050px) and (max-width:1300px){

  .navbar .container {
    max-width: 99%;
  }

  .contact {
    .col-md-5{
      width: 52%;
    }
   .contact-form{
  width: 48%;  
  padding: 49px 40px 70px 40px;
  h4 {
    font-size: 41px;
  }
  .color-gray{
    margin-top: 2%;
  }
  form{
      margin-top: 1.25rem !important;
  }
  }
}
  .process-detail p {

    color: #8d929f;
    width: 74%;
}
.design-preocess {
  background-position: 100% center, 143% 40%, top right;
}
.home-banner .text-gray {
  max-width: 43%;
  font-size: 17px;
}

.home-banner .innovate {
  font-size: 27px;
  letter-spacing: 2.84px;
}
.home-banner .banner-img {height: 514px;}

.home-banner {
  background-size: 28rem;
  background-position: 68% 11%, 70% 20%, top right;
  height: 650px;

  p.fw-600.mb-0.pt-80.intro-line {
    padding-top: 40px;
  }

  }

  .footer .footer-nav {
    gap: 10%;
}
.service {
  min-height: 78vh;
}
.service .title {
    font-size: 34px;
}
.work-title{
  width: 98%;
  margin-left: 1%;
}
.work-section .work .align-self-center {
  display: flex;
  width: 21%;
  flex-direction: column;
  height: 100%;
  .me-3 {
    margin-right: 0 !important;
}

  .button-round{
     margin-bottom: 10%;
    }

  div{
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}
.work-experience-section .row {
  display: flex;
  justify-content: space-between;
  .col-md-6{
    width: 43%;
  }
}
.service .service-count {
  font-size: 74px;
}

.about-banner .about-heading {
  font-size: 8.5rem;
    width: 100
}
.about-banner{
  background-size: 34%;
  background-position: 98% 29%;
}
.main-nav{
  margin-top: -1%;
}

.values-section .values {
  max-width: 76%;

  .value{
    height: 126px;
    padding: 10px;
    .fs-small {
      font-size: 0.84rem;
  }
    .mb-1.mt-1{
      font-size: 18px;
    }
    img{
      width: 30%;
    }
  } 
}

@media screen and (min-width:1050px) and (max-width:1175px) {
  .service {
    min-height: 85vh;
  }
.home-banner {
  background-position: 74% 8%, 70% 20%, top right;
.text-gray {
    max-width: 43%;
    font-size: 15px;
}
}
.contact-form h4{
  font-size: 36px;
}
.contact form .cb_cont {

 div {
  width: 100%;
  }
}

}

}

////////////////////////////////////////////////////////////////////////////////// +800

@media screen and (min-width:790px) and (max-width:1050px){

  .nav-bottom{
    margin-top: 10%;
    position: relative !important;
  }
  .navbar .container{
      max-width: 99%;
  }
  .contact {
    .contact-form{
      width: 48%;padding: 22px;

    .color-gray{    
      margin-top: 6%;
      margin-bottom: 7%;}

    #firstRow{flex-direction: column;}
    .input_cont{width: 97%;}
    }
    .col-md-5 {
      width: 52%;
      
  }  
  }
  .contact form .cb_cont {
    flex-direction: column;
    margin-bottom: 7%;
    .c1, .c2 {
      width: 100%;
    }
    div {
     width: 100%;
     margin-top: 1%;

     }
   }
  .about-banner {
    background-position: 99% 19%;
    background-size: 41%;
    .about-heading{
      font-size: 6.5rem;
      width: 100%;
      letter-spacing: 8px;
    }
  }
  .values-section .tool-section {
    width: 186px;
    img{
    height: 100px;
    }
}
  .vertical-line {
    top: 73px;
  }

  .work-experience-section{
  //  min-height: 249vh;
   
    .row .col-md-6:nth-child(2) {
      flex: 0 0 auto;
        width: 72%;
        margin-top: 14%;
    }

  }

.carousel img {
    max-height: 73vh;
}
  
.accordion .accordion-item .button{
  font-size: 20px;
}

.banner-heading {
  letter-spacing: 5.62vw;
}
  .home-banner{
    background-size: 20rem;
    background-position: 94% 1%, 70% 20%, top right;
    height: 88vh;

   .home-buttons {
          gap: 17px;}
    
    .button-dark,.button-outline {
            padding: 13px 24px;
            font-size: 15px;
        }

    p.fw-600.mb-0.pt-80.intro-line {
          padding-top: 0;
        }

   .innovate {
    display: none;
   }
   .text-gray {
    font-size: 14px;
    max-width: 56%;
    margin-bottom: 0;
    margin-top: 0;
   }
   .banner-img {
    height: 383px;
    position: absolute;
    left: 59%;
    top: 0%;
   
}
.justify-content-between {
  width: 105%;
}}


.services {
  padding: 60px 26px;

}
.services .col {
  padding: 0 17px;
  margin-bottom: 7%;
}
.service {
  min-height: 98vh;


  h5{
    margin-top: -7px;
  }
  img {
    margin-top: 6%;
  }
  .service-count{
    font-size: 68px;
  }
  .text-gray{

    font-size: 17px;

  }
}

.work-section {
  .work-title{
    width: 97%;
    margin-left: 2%;
      .col-md-5{
        width: 35%;
      }
      .text-gray {
        text-align: end;
      }
  }
  .text-gray {width: 65%;}
  .work {
    .text-gray{width: 100%; font-size: 14px;}
  }
  .work .align-self-center div{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  
      .button-round{
       margin-bottom: 10%;
       font-size: 14px;
      }
      .me-3 {
        margin-right: 0 !important;
      }
  }

  .text-white{
    width: 35%;
    h5{
      font-size: 28px;
    }
  }
}

.design-preocess{
  background-position: 100% center, 47% 49%, top right;

.process-section{
  margin-top: 6%;


  .process:nth-child(1) {
    margin-left: 16%;
}
  .process:nth-child(2) {
    margin-left: -3%;
    .color-gray{
      width: 57%;
    }
}
.process:nth-child(3) {
  margin-left: 44%;
  margin-top: -4%;
}
}  
}


.faq-section .faq {
  max-width: 80%;
}
.faq-heading {
  margin-bottom: 5%;
  font-size: 51px;
}

.values-section .values {
  max-width: 86%;

  .value{
    height: 126px;
    padding: 8px;
    .fs-small {
      font-size: 0.84rem;
  }
    .mb-1.mt-1{
      font-size: 18px;
    }
    img{
      width: 30%;
    }
  } 
}

.footer {
  .nav-icons{
    width: 100%;
    justify-content: center !important;
    gap: 37px;
  }
  .footer-nav{
    gap: 63px;
  }
 
  #responsiveRow {
      display: flex;
      flex-direction: column;
      align-items: center;
      
  
      .col-md-4 {
        width: 100%;
        display: flex;
        justify-content: center;
      }
  }}
  .modal-body .color-gray {
    width: 45%;
  }

  @media screen and (min-width: 950px) and (max-width: 1050px){
    .home-banner {
      background-position: 88% 0%, 70% 20%, top right;

        .banner-img {
          top: -1%;
      }
    }
    .service {
    min-height: 92vh;
  }}
    
  @media (min-height: 900px) {
    .home-banner{
      height: 49vh
    }
    .about-banner .about-heading {
      padding-top: 11%;
      line-height: 18cqh;
}
.banner-heading {
  line-height: 19cqh;
}
.service {
  min-height: 51vh;
}
.work-carousel .modal-fullscreen .modal-content{
  height: 49%;
}
}
@media (min-height: 700px) {
  .service {
    min-height: 68vh;
}
.design-preocess {
  background-position: 100% center, 37% 58%, top right;
}
.work-carousel .modal-fullscreen .modal-content {
  height: 85%;
}
.home-banner {
  height: 75vh;
}
.home-banner .banner-img {
  top: -1%;
  left: 60%;
}
}
}

/////////////////////////////////////////////////////////////// celulares
/// 
@media screen and (min-width:300px) and (max-width:790px) {

  form {
    margin-top: 6%;
  }
  .contact {
    .contact-form{width: 100%; padding: 49px 42px 48px 45px;}
  }
  .contact form .cb_cont {
    display: flex;
    flex-direction: column;
    margin-bottom: 8%;
    .c1, .c2 {
      width: 100%;
    }
    div {
     width: 100%;
     }
   }
  

  .footer #responsiveRow {
    display: flex;
    flex-direction: column;
    align-items: center;

    .float-start {
      float: unset !important;
  }  
  }

  .main-nav .nav-link {
    font-size: 2rem;
  }

  .nav-bottom {
    display: block;
    font-size: 12px;
  }

  .navbar .container {
    max-width: 99%;
  }

  .offcanvas-header {
    padding: 20px;
  }

  .services .col {
    border-right: none;
    border-bottom: 1px solid $color-gray-three;
  }
  .services .col:nth-child(1) {
    margin-top: 5%;
  }

  .footer {
    padding: 50px 40px 0 40px;

    .footer-nav {
      gap: 20px;
    }

    .nav-link {
      margin-right: 0;
    }
  }
  .values-section .tool-section img {
    height: 107px;
}
  .values-section .values {
    max-width: 86%;
  
    .value{
      flex: 0 0 32.333333%;
      height: 126px;
      padding: 8px;
      .fs-small {
        font-size: 0.9rem;
    }
      .mb-1.mt-1{
        font-size: 18px;
      }
      img{
        width: 24%;
      }
    } 
  }
  .work-experience-section {
    min-height: 1669px;

    .vertical-line {
      padding-top: 98%;
    }
  }
  .service {
    min-height: 300px;
    .position-absolute {
      position: relative !important;
    }
    img {
      height: 52px;
    }
    .title {
      font-size: 30px;
      white-space: normal; 
      word-spacing: 0px;
    }
    .service-count{
      font-size: 72px;
    }
  }
  .work-section {
    padding: 40px 25px;

    .align-self-center div {
      display: flex;
    justify-content: space-around;
    margin-top: 9%;
    .me-3 {
       margin-right: 0rem !important; 
  }
    }

    .work {
      .work-detail {
        display:block !important;
      }
      padding: 0px;
      .button-round {
        margin-bottom: 15px;
        font-size: 14px;
      }
      .image-large {
        min-height: 160px;
      }
      .float-end {
        float: left !important;
      }
    }
    .work-title {
      margin-bottom: 0px;
      margin-left: -2%;
    }
    .text-end {
      text-align: left !important;
      a {
        display: block;
      }
      .divider {
        display: none;
      }
    }
  }
  .work-carousel {
    .work-desc {
      display: block !important;
    }
    .carousel {
      margin-top: 20px;
      img {
          min-height: 28vh;
      }
    }
    .carousel-control-prev {
      margin-left: -20px;
    }
    .carousel-control-next {
      margin-right: -20px;
    }
    .img-sm img {
      max-width: 53px;
    }
    .modal-fullscreen .modal-content {
      height: 98%;
      width: 95%;
      border-radius: 4px;
      .modal-header {
        padding: 16px 10px;
      }
      .modal-body {
        padding: 0 10px 30px 10px;
        .display-chips {
          gap: 8px;
          justify-content: center;
        }
      }
    }
    .pill-orange {
      padding: 5px 11px;
      font-size: 12px;
      margin-left: 20px;
    }
  }
  .design-preocess {
    background-image: url(../assets/images/design-process-background.png);
    padding: 70px;
    .process-detail {
      margin-left: 0;
    }
    .process-section {
      .process:nth-child(2) {
        margin-left: 0;
      }
      .process:nth-child(3) {
        margin-left: 0;
      }
    }
  }

  .home-banner {
       padding: 0 2rem;
       height: 530px;
       background-size: 12rem;
       background-position: 96% 6%, 32% 20%, top right;

    .text-gray {
          width: 57%;
    font-size: 15px;
    }
    .scroll-down-animation {
      display: none;
    }
    .pt-80 {
      padding-top: 30px;
          font-size: 12px;
    }

    .banner-img {
        height: 221px;
    position: absolute;
    left: 56%;
    top: 2%;
    }

    .banner-heading {
      font-size: 65px;
      letter-spacing: 21px;
      font-weight: 500;
      line-height: 78px;
      margin-left: 0;
    }

    .innovate {
      display: none;
    }

    .home-buttons {
      display: block;

      .button-dark {
        margin-bottom: 20px;
      }
    }
  }
  .about-banner {
    background-position: 97% 10%;
    background-size: 30%;
    padding: 0 39px 80px;
    margin-top: 5%;
    .scroll-down-animation {
      display: none;
    }
    .about-heading {
      margin-bottom: 5%;
      font-size: 50px;
      letter-spacing: 8px;
      font-weight: 500;
      line-height: inherit;
      width: 50%;
    }
  }
  .work-experience-section {
    padding: 80px 40px;
    .row > div:last-child {
      margin-top: 80px;
    }
  }
  .contact-page {
    .scroll-down-animation {
      display: none;
    }
  }
  .contact-confirm {
    img {
      height: 100%;
      width: 100%;
    }
    .contact-submit {
      padding: 40px 40px 80px 40px;
    }
  }
  .footer .buttons {
    display: inline-block !important;
    .button-outline {
      margin: auto;
    }
  }
  .footer .nav-icons {
    justify-content: center !important;
  }


    .footer .text-small {
      font-size: 19px;
      margin-bottom: 4%;
  
  }

  .nav-bottom {
    display: block;
    font-size: 12px;
    margin-top: 21%;
    position: relative !important;
}

  @media screen and (min-width:600px) and (max-width:790px) {


    .about-banner {
      background-position: 88% 3%;
      background-size: 182px;
      padding: 0 39px 80px;
  }
    .about-banner .about-heading {
      font-size: 76px;
      width: 50%;
      margin-bottom: 2%;
      line-height: 115%;
  }
  .home-banner .banner-img {
    left: 59%;
}
    .work-experience-section .vertical-line {
      padding-top: 55%;
  }

 
      .home-banner {
        background-size: 12rem;
        background-position: 82% 3%, 32% 20%, top right;
    }
    
    .banner-img {
      height: 221px;
      position: absolute;
      left: 64%;
      top: 2%;
    }

    .process-section{
      margin-top: 3%;
    margin-bottom: 2%;
    }

    .carousel img{
      min-height: 60vh;
    }
  }


  @media screen and (min-width:300px) and (max-width:430px) {
    .carousel img {
      min-height: 28vh;
  }
    .home-banner {
      background-size: 11rem;
      background-position: 98% 7%, 32% 20%, top right;

      .banner-heading {
        letter-spacing: 17px;
      }
      .banner-img {
        height: 206px;
        position: absolute;
        left: 55%;
        top: 4%;
      }
      .home-buttons{
        margin-top: 18px;
      }
    }

    .design-preocess .text-center {
      width: 105%;
      margin-bottom: 10%;
    }
    .footer .text-small {
        font-size: 18px;
        margin-bottom: 7%;
    }
    .values-section .values {
      max-width: 100%;
    
      .value{
        height: 157px;
        padding: 8px;
        .fs-small {
          font-size: 0.9rem;
      }
        .mb-1.mt-1{
          font-size: 18px;
        }
        img{
          width: 28%;
        }
      } 
    }
}

}

$color_orange: #fd7050;
$offcanvas-bg-color: #fd7050;
$color-grey: #f2f2f2;
$color-gray-two: #8d929f;
$color-gray-three: #e1e3e4;
$offcanvas-padding-y: 20px;
$offcanvas-padding-x: 80px;
$h1-font-size: 4.5rem;
$h2-font-size: 4rem;
$h3-font-size: 3.5rem;
$h4-font-size: 3rem;
$h5-font-size: 2.5rem;
$h6-font-size: 2rem;
$accordion-bg: transparent;
$accordion-color: #ffffff;
$accordion-border-color: #3a3e46;
$accordion-active-bg: transparent;
$accordion-active-color: #ffffff;
$bs-accordion-btn-padding-y: 30px;
$bs-accordion-btn-focus-box-shadow: none;
$modal-header-border-width: 0px;
$h2-font-weight: 600;